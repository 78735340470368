<template>
  <div>
    <ExtendedView :tabs="tabs">
      <template slot="list">
        <ActionList
          :call-to-actions="callToActions"
          :loading="false"
          :preloaded-items-list="categories"
          empty-text="No categories yet"
        >
          <template v-slot:item="{ item, index }">
            <CategoryListItem
              :category="item"
              :key="item.uuid"
              @selectCategory="selectCategory(item)"
            />
            <v-divider
              :key="index"
              inset
            />
          </template>
        </ActionList>
      </template>
      <template slot="profile">
        <CategoryProfile :category="selectedCategory" />
      </template>
    </ExtendedView>
    <CreateCategoryDialog
      v-model="showCreateDialog"
      @close="showCreateDialog = false"
      :category="newCategory"
      @created="categoryCreated"
    />
  </div>
</template>

<script>
import ExtendedView from "@/components/common/ExtendedView"
import ActionList from "@/components/common/list/ActionList"
import CategoryListItem from "@/components/CategoryListItem"
import CreateCategoryDialog from "@/components/category/CreateCategoryDialog"
import CategoryProfile from "@/components/category/CategoryProfile"

export default {
	name: 'Categories',
	components: {
		CreateCategoryDialog,
		ExtendedView,
		ActionList,
		CategoryProfile,
		CategoryListItem
	},
	data: () => ({
		processing: false,
		showCreateDialog: false,
		selectedCategory: null,
		newCategory: {
			name: '',
			description: '',
			weight: 0,
			parent: null,
			required: false,
			single: false,
			administrative: false,
			opening_hours: [],
			ept: 0,
			primary_color: '',
			image: null,
			allowSplitByHalf: false,
			accountingKey: ''
		},
	}),
	methods: {
		selectCategory(category) {
			this.selectedCategory = category
		},
		showCreateCategoryDialog() {
			this.showCreateDialog = true
		},
    categoryCreated(category) {
      this.$store.commit('updateActionSuccess', {
        message: this.$t('success'),
        subMessage: this.$t('category') + ' ' + category.name + ' ' + this.$t('wasCreated')
      })
      this.newCategory = {
        name: '',
        description: '',
        weight: 0,
        parent: null,
        required: false,
        single: false,
        administrative: false,
        opening_hours: [],
        ept: 0,
        primary_color: '',
        image: null,
        allowSplitByHalf: false,
        accountingKey: ''
      }
    }
	},
	computed: {
		callToActions() {
			return [{
				title: this.$t('addCategory'),
				callback: this.showCreateCategoryDialog
			}]
		},
		categories() {
			return this.$store.getters.categories
		},
		selectedAppBarTab() {
			return this.$store.state.selectedAppBarTab
		},
		tabs() {
			const tabs = []

			tabs.push({
				title: this.$t('list'),
				key: 'list'
			})

			return tabs
		}
	},
	mounted() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	}
}
</script>
